import {GraphQL} from '../../graphql';
import {ApiBase} from './ApiBase';
import {StoreSettings} from '@wix/wixstores-graphql-schema-node';

export class StoreSettingsApi extends ApiBase {
  /* istanbul ignore next */
  public async getStoreSettings(): Promise<StoreSettings> {
    const response = await this.siteStore.tryGetGqlAndFallbackToPost(this.endpoint, {
      ...GraphQL.getStoreSettings(),
      operationName: 'getStoreSettings',
      source: 'WixStoresWebClient',
    });

    return response.data.settings;
  }
}
