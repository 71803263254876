import {StoreSettingsApi} from '../apis/StoreSettingsApi';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {StoreSettings} from '@wix/wixstores-graphql-schema-node';

export class StoreSettingsService {
  private readonly storeSettingsApi: StoreSettingsApi;
  private storeSettings: StoreSettings;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.storeSettingsApi = new StoreSettingsApi({siteStore});
  }

  public async fetch() {
    this.storeSettings = await this.storeSettingsApi.getStoreSettings();
    return this.storeSettings;
  }

  public getFetchedData() {
    return this.storeSettings;
  }
}
