import {DynamicPaymentMethodsShape, DynamicPaymentMethodsTheme} from '@wix/wixstores-client-storefront-sdk';
import _ from 'lodash';
import {ICartStyleSettings} from '../../types/app.types';
import {StyleParam} from './constants';
import {CheckoutButtonsPosition} from '@wix/wixstores-client-core';

export function getCartSettingsFromStyles(rawStyles: ICartStyleSettings): ICartStyleSettings {
  const defaultStyles: ICartStyleSettings = {
    booleans: {
      [StyleParam.ShowContinueShopping]: false,
      [StyleParam.ShowCoupon]: true,
      [StyleParam.ShowExpressCheckout]: true,
      [StyleParam.ShowBuyerNote]: true,
      [StyleParam.ShowTax]: false,
      [StyleParam.ShowShipping]: true,
      [StyleParam.Responsive]: false,
    },
    fonts: {
      [StyleParam.CornerRadius]: undefined,
      [StyleParam.SelectedSkin]: undefined,
    },
    numbers: {
      [StyleParam.dynamicPaymentMethodsShape]: DynamicPaymentMethodsShape.rect,
      [StyleParam.dynamicPaymentMethodsTheme]: DynamicPaymentMethodsTheme.dark,
      [StyleParam.CheckoutButtonsPosition]: Number(CheckoutButtonsPosition.ABOVE_AND_BELOW_CART),
    },
  };

  return _.merge({}, defaultStyles, rawStyles);
}
